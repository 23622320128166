//export const API_PREFIX ='http://ec2-13-127-15-209.ap-south-1.compute.amazonaws.com:8080/';
//export const API_PREFIX = "http://192.168.1.6:8080/";
export const API_PREFIX = "https://admin.mandi24.in/api/";
export const GOOGLE_APIURL =
  "https://maps.googleapis.com/maps/api/geocode/json?";
export const IMAGE_PREFIX =
  "https://mandi24bucket.s3.ap-south-1.amazonaws.com/";
export const MAP_APIKEY = "AIzaSyCGUgcptdrMfgIBi6YrnolwEF-tH1rEEno";

export const CRYPTO_KEY = "ILOPOoR1hBaRKzBTEQITHjcy1mQ4Et4OZSoUugd5";

export const CRYPTO_IV = "ILOPOoR1hBaRKzBTEQITHjcy1m";

export const AWS_URL = "https://mandi24bucket.s3.ap-south-1.amazonaws.com/";
