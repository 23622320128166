import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  Toolbar,
  List,
  CssBaseline,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Menu as MenuIcon,
  ChevronLeft,
  ChevronRight,
  Mail,
  Person3Sharp,
  GroupSharp,
  SettingsSharp,
  CategorySharp,
} from "@mui/icons-material";
import {
  MENU_ITEMS,
  SUBMENU_USER,
  SUBMENU_MASTERSETTING,
  SUBMENU_ITEM,
  SUBMENU_USER_LIST,
  MESSAGE,
} from "../../../utils/constant/menuConstant";
import styles from "./styles";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const drawerRef = useRef(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    localStorage.clear();
    document.cookie = ""; // Clear all cookies
    navigate("/login");
  };
  const handleMenuClick = (index) => {
    if (selectedIndex === index) {
      setSelectedIndex(null);
    } else {
      setOpen(true);
      setSelectedIndex(index);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setSelectedIndex(null);
  };

  const handleSubmenuClick = (path) => {
    navigate(path);
  };

  const subMenuItem = (item) => {
    const role = parseInt(localStorage.getItem("role"));
    return (
      <>
        {item.map((submenu, subIndex) => {
          if (
            (submenu.title === "Admin User" ||
              submenu.title === "Add Admin User") &&
            role !== 2
          ) {
            return null;
          }
          return (
            <ListItem
              button
              key={submenu.title}
              onClick={() => handleSubmenuClick(submenu.path)}
            >
              <ListItemText primary={submenu.title} />
            </ListItem>
          );
        })}
      </>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setOpen(false);
        setSelectedIndex(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [drawerRef]);

  const handleChangePassword = () => {
    navigate("/changePassword");
  };

  const handleMenuIcon = (item) => {
    const text = item.title.toLowerCase();
    switch (text) {
      case "admin user":
        return <Person3Sharp />;
      case "user list":
        return <GroupSharp />;
      case "master setting":
        return <SettingsSharp />;
      case "item":
        return <CategorySharp />;
      case "message":
        return <Mail />;
      default:
        return <Mail />;
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Button onClick={() => navigate("/adminHome")} color="inherit">      
              Welcome to Mandi24 Admin Panel           
          </Button>
          <Button
            color="inherit"
            sx={styles.settingMenus}
            onClick={handleMenuOpen}
          >
            Setting
          </Button>
          <Menu
            id="setting-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleMenuClose}>profile</MenuItem>
            <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer ref={drawerRef} variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={styles.menu}>
          {MENU_ITEMS.map((item, index) => (
            <div key={item.title}>
              <ListItem button onClick={() => handleMenuClick(index)}>
                <ListItemIcon>{handleMenuIcon(item, index)}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItem>
              {selectedIndex === index && (
                <List sx={styles.submenu}>
                  {item.title === "Admin User" && subMenuItem(SUBMENU_USER)}
                  {item.title === "User List" && subMenuItem(SUBMENU_USER_LIST)}
                  {item.title === "Master Setting" &&
                    subMenuItem(SUBMENU_MASTERSETTING)}
                  {item.title === "Item" && subMenuItem(SUBMENU_ITEM)}
                  {item.title === "Message" && subMenuItem(MESSAGE)}
                </List>
              )}
            </div>
          ))}
        </List>
      </Drawer>
      {/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />       
      </Box> */}
    </Box>
  );
}
